import React from "react";

import { Container, Heading, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import {
  FiBriefcase,
  FiGift,
  FiGlobe,
  FiSend,
  FiTruck,
  FiTool,
  FiCreditCard,
  FiTrello,
  FiArchive,
  FiDollarSign,
  FiTrendingUp,
  FiFile,
} from "react-icons/fi";

import Feature from "./Feature";

const featuresData = [
  {
    icon: FiTrendingUp,
    iconBg: "#F75C4E",
    heading: "Track Leads",
    text: "See where your money goes and comes in in your property. Remember that you have full control over your properties and your rentals. See all the data relevant to all your properties.",
  },
  {
    icon: FiTruck,
    iconBg: "#191046",
    heading: "Remote Mode",
    text: "Use our platform in the comfort of your phone or laptop to connect. You dont need to be on the ground.",
  },
  {
    icon: FiTrello,
    iconBg: "#FF1FB3",
    heading: "Advertise Properties",
    text: "Properties and rentals are advertised in the system as long as they are vacant. So worry no more about your rentals not getting enough coverage.",
  },
  {
    icon: FiGift,
    iconBg: "#5C4EF7",
    heading: "Reward System",
    text: "We motivate everyone in the app with promocodes by having free promocodes on regular basis so as to boost user morale in our platform.",
  },
  {
    icon: FiTool,
    iconBg: "#F7954E",
    heading: "Maintenance Requests",
    text: "Our platform allows for users to post things needing maintenance in the rental and landlords and caretakers to see what needs to be fixed.",
  },
  {
    icon: FiBriefcase,
    iconBg: "#1F7CFF",
    heading: "Deposits",
    text: "Your deposit is sent to you immediately when you agree with the landlord on things you have destroyed and the amounts. You dont have to worry about money taking long to reach you.We got you. ",
  },
  {
    icon: FiArchive,
    iconBg: "#F75C4E",
    heading: "Reservations",
    text: "See who has reserved your rentals in your property. Manage all reservations and see who has paid their bills and who has not.",
  },
  {
    icon: FiCreditCard,
    iconBg: "#191046",
    heading: "Rent Payment",
    text: "Pay for your rent using multiple payment channels.{mpesa,airtel money, vooma, equity.. eazzy pay, pesa link, union pay, amex , visa, master card}",
  },
  {
    icon: FiDollarSign,
    iconBg: "#FF1FB3",
    heading: "Settlement",
    text: "You get to settle yourself over multiple channels in both mobile channels and card channels anytime of the day. {mpesa,airtel money, vooma, equity.. eazzy pay, pesa link, union pay, amex , visa, master card} ",
  },
  {
    icon: FiSend,
    iconBg: "#5C4EF7",
    heading: "Vacate",
    text: "The vacate feature where you have controll over your time of leaving and the items you have destroyed and have your money deposited to your account in no time.",
  },
  {
    icon: FiGlobe,
    iconBg: "#F7954E",
    heading: "Bills",
    text: "View your rent bills and waterbills on your phone and pay for them.",
  },
  {
    icon: FiFile,
    iconBg: "#F7954E",
    heading: "Support",
    text: "Get to contact us anytime of the day for any issues you encounter. We are here to help!",
  },
];

function Features() {
  return (
    <Container size="md" pt={{ base: "5rem", md: "8.125rem" }}>
      <VStack spacing={{ base: "3rem", md: "4.25rem" }}>
        {/* Heading */}
        <VStack spacing="1.25rem">
          <VStack spacing="0.5rem">
            <Text fontWeight="700" color="red.400" textAlign="center">
              WORK BETTER
            </Text>
            <Heading as="h2" size="lg" textAlign="center">
              For Your Business
            </Heading>
          </VStack>
          <Text color="muted" maxW="25rem" textAlign="center" lineHeight="2rem">
            We did research what your property needs and here we are providing
            all of them just for you
          </Text>
        </VStack>
        {/* Grid */}
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          spacingY="3.75rem"
          spacingX="5.25rem"
          maxW="1105px"
        >
          {featuresData.map((featureData, index) => (
            <Feature
              key={index}
              icon={featureData.icon}
              iconBg={featureData.iconBg}
              heading={featureData.heading}
              text={featureData.text}
            />
          ))}
        </SimpleGrid>
      </VStack>
    </Container>
  );
}

export default Features;
