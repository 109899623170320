// import React from "react";
// import {
//   Button,
//   Container,
//   HStack,
//   Heading,
//   Image,
//   Show,
//   Text,
//   VStack,
// } from "@chakra-ui/react";

// function Hero() {
//   return (
//     <Container
//       size="md"
//       pt={{ base: "0.5rem", md: "3.75rem" }}
//       pr={{ xl: "0.25rem" }}
//     >
//       <HStack justify={{ base: "start", md: "center", xl: "space-between" }}>
//         <VStack
//           maxW={{ md: "28.125rem", xl: "100%" }}
//           align={{ base: "start", md: "center", xl: "start" }}
//           textAlign={{ base: "start", md: "center", xl: "start" }}
//           spacing={{ base: "1.6rem", md: "1.65rem" }}
//         >
//           <Heading as="h1" size="3xl">
//             Manage Properties Like an Expert with iNyumba
//           </Heading>
//           <Text color="muted" lineHeight="taller">
//             iNyumba is helping you to set up your properties, manage your
//             tenants data and settling yourself with as many card and mobile
//             channels as possible. As for tenants they are able to see as many
//             properties as possible choose between various types of houses,
//             reserve and pay for rent using different payment
//             channels.mpesa,airtel money, vooma, equity.. eazzy pay, pesa link,
//             union pay, amex , visa, master card
//           </Text>
//           <Button colorScheme="blue">Get Started</Button>
//         </VStack>

//         <Show above="xl">
//           <Image src="assets/images/hero-image.png" alt="Hero image" />
//         </Show>
//       </HStack>
//     </Container>
//   );
// }

// export default Hero;

import React from "react";
import {
  Button,
  Container,
  HStack,
  Heading,
  Image,
  Show,
  Text,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Link,
} from "@chakra-ui/react";

function Hero() {
  return (
    <Container
      size="md"
      pt={{ base: "0.5rem", md: "3.75rem" }}
      pr={{ xl: "0.25rem" }}
    >
      <HStack justify={{ base: "start", md: "center", xl: "space-between" }}>
        <VStack
          maxW={{ md: "28.125rem", xl: "100%" }}
          align={{ base: "start", md: "center", xl: "start" }}
          textAlign={{ base: "start", md: "center", xl: "start" }}
          spacing={{ base: "1.6rem", md: "1.65rem" }}
        >
          <Heading as="h1" size="3xl">
            Manage Properties Like an Expert with iNyumba
          </Heading>
          <Text color="muted" lineHeight="taller">
            iNyumba is helping you to set up your properties, manage your
            tenants data and settling yourself with as many card and mobile
            channels as possible. As for tenants they are able to see as many
            properties as possible choose between various types of houses,
            reserve and pay for rent using different payment
            channels.mpesa,airtel money, vooma, equity.. eazzy pay, pesa link,
            union pay, amex , visa, master card
          </Text>
          {/* Popover with links */}
          <Popover>
            <PopoverTrigger>
              <Button colorScheme="blue">Get Started</Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverBody>
                <Link
                  href="https://landlord.inyumba.co.ke/registration"
                  isExternal
                >
                  Register as Landlord
                </Link>
                <br />
                <Link
                  href="https://tenant.inyumba.co.ke/registration"
                  isExternal
                >
                  Register as Tenant
                </Link>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </VStack>

        <Show above="xl">
          <Image src="assets/images/hero-image.png" alt="Hero image" />
        </Show>
      </HStack>
    </Container>
  );
}

export default Hero;
