import React from "react";

import {
  Box,
  Container,
  Heading,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

import VideoPlayButton from "assets/svg/video-play-button.svg";

function CTA() {
  return (
    <Container
      size="md"
      pt={{ base: "5rem", md: "8.125rem" }}
      pb={{ base: "2.75rem", md: "4.5rem", xl: "7.75rem" }}
    >
      <Stack
        direction={{ base: "column", xl: "row" }}
        align="center"
        justify="center"
        spacing="4.375rem"
      >
        {/* Video Preview */}
        <Box position="relative">
          <a
            href="https://www.dropbox.com/scl/fi/d9xo7ykjtoigkju5urmva/0422.mp4?rlkey=7oybal8f78auza30ogl02l93d&st=rts2k0ps&raw=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              src="https://www.dropbox.com/scl/fi/5qeolvr9hnmg7amue63up/inyumbatwo.png?rlkey=am75yoke6inayof82d6xckeiv&st=kfmabm8q&raw=1"
              alt="image preview"
              borderRadius="2.5rem"
              maxW={{ base: "522px", xl: "635px" }}
              w="100%"
            />
            <Box
              pos="absolute"
              top="50%"
              left="50%"
              transform="auto"
              translateX="-50%"
              translateY="-50%"
            >
              <VideoPlayButton />
            </Box>
          </a>
        </Box>
        {/* Text */}
        <VStack align="stretch" maxW="429px">
          {/* Headings */}
          <VStack spacing="0.5rem" align="stretch">
            <Text
              fontWeight="700"
              color="red.400"
              textAlign={{ base: "center", xl: "start" }}
            >
              SAVE MORE TIME
            </Text>
            <Heading
              as="h2"
              size="lg"
              textAlign={{ base: "center", xl: "start" }}
            >
              And Boost Productivity
            </Heading>
          </VStack>
          {/* Text */}
          <Text
            color="muted"
            maxW="25rem"
            pt="0.75rem"
            textAlign={{ base: "center", xl: "start" }}
            lineHeight="2rem"
          >
            Your dont need tenants sending their mpesa messages as proof of
            payment. Check your page and see clients who have paid.
            Automatically advertise empty rentals in the platform.
          </Text>
          <VStack align="stretch" maxW="429px">
            {/* Headings */}
            <Heading
              as="h2"
              size="lg"
              textAlign={{ base: "center", xl: "start" }}
            >
              Contact us
            </Heading>
          </VStack>
          <Text
            color="muted"
            maxW="25rem"
            pt="0.75rem"
            textAlign={{ base: "center", xl: "start" }}
            lineHeight="2rem"
          >
            Email: customerservice@inyumba.co.ke
          </Text>
          <Text
            color="muted"
            maxW="25rem"
            pt="0.75rem"
            textAlign={{ base: "center", xl: "start" }}
            lineHeight="2rem"
          >
            Phone Number: 0742284044
          </Text>
          <Text
            color="muted"
            maxW="25rem"
            pt="0.75rem"
            textAlign={{ base: "center", xl: "start" }}
            lineHeight="2rem"
          >
            Alternative Number: 0757930647
          </Text>
          <Text
            color="muted"
            maxW="25rem"
            pt="0.75rem"
            textAlign={{ base: "center", xl: "start" }}
            lineHeight="2rem"
          >
            Instagram: inyumba.co.ke
          </Text>

          <Heading
            as="h2"
            size="lg"
            textAlign={{ base: "center", xl: "start" }}
          >
            Terms and Conditions
          </Heading>
          <Text
            color="muted"
            maxW="25rem"
            pt="0.75rem"
            textAlign={{ base: "center", xl: "start" }}
            lineHeight="2rem"
          >
            Please review our terms and conditions and only register or use our
            platform if you are comfortable with them.{" "}
            <a
              href="https://backend.inyumba.co.ke/termsandconditions/termsandconditions.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here to see terms and conditions.
            </a>{" "}
          </Text>
        </VStack>
      </Stack>
    </Container>
  );
}

export default CTA;
